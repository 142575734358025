/* THIS IS A GENERATED FILE - DO NOT MODIFY */

/* eslint-disable */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type Activity = {
  __typename?: 'Activity';
  activityPlanId: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  file?: Maybe<TeamFile>;
  fileId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ActivityInput = {
  activityPlanId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  fileId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type ActivityPlan = {
  __typename?: 'ActivityPlan';
  activities?: Maybe<Array<Maybe<Activity>>>;
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isTemplate: Scalars['Boolean']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ActivityPlanInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  isTemplate: Scalars['Boolean']['input'];
  templateId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type ActivityPlanUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  isTemplate: Scalars['Boolean']['input'];
  templateId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type ActivityUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  fileId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type AdminMetrics = {
  __typename?: 'AdminMetrics';
  feedPostCount: Scalars['Int']['output'];
  liveStreamCount: Scalars['Int']['output'];
  teamCount: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
};

export type AdminModifyUserInput = {
  coach?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isContentCreator?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  proTeamId?: InputMaybe<Scalars['Int']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AnalyticsCoachPosition {
  Assistant = 'ASSISTANT',
  Coach = 'COACH',
  MainCoach = 'MAIN_COACH'
}

export enum AnalyticsEvent {
  JoinTeam = 'JOIN_TEAM',
  LeaveTeam = 'LEAVE_TEAM',
  NewTeam = 'NEW_TEAM',
  NewUser = 'NEW_USER',
  SetPosition = 'SET_POSITION'
}

export enum AnalyticsTeamRole {
  Coach = 'COACH',
  Player = 'PLAYER'
}

export type AnnouncementPushData = {
  __typename?: 'AnnouncementPushData';
  announcementId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
};

export type Answer = {
  __typename?: 'Answer';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  pollId: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userAnswerCount: Scalars['Int']['output'];
  userAnswers: Array<UserAnswer>;
};

export type AttachmentInput = {
  messageType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthMethodsConfig = {
  __typename?: 'AuthMethodsConfig';
  login: Array<Maybe<Scalars['String']['output']>>;
  registration: Array<Maybe<Scalars['String']['output']>>;
};

export type AuthMethodsConfigInput = {
  login: Array<InputMaybe<Scalars['String']['input']>>;
  registration: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  newPasswordConfirmation: Scalars['String']['input'];
};

export type ChatAttachment = {
  __typename?: 'ChatAttachment';
  messageType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ChatConversation = {
  __typename?: 'ChatConversation';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  messages: Array<ChatMessage>;
  name?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  type: ChatConversationType;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum ChatConversationType {
  Coach = 'COACH',
  Parent = 'PARENT',
  Player = 'PLAYER',
  Private = 'PRIVATE',
  Team = 'TEAM'
}

export type ChatMessage = {
  __typename?: 'ChatMessage';
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<Array<Maybe<ChatAttachment>>>;
  chatConversationId: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mediaUrl?: Maybe<Scalars['String']['output']>;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type ChatMessageInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  chatConversationId: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  mediaUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Collection = {
  __typename?: 'Collection';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  itemCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type CollectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum CollectionItemType {
  Inventory = 'INVENTORY',
  Wishlist = 'WISHLIST'
}

export type CollectionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type CommentInput = {
  content: Scalars['String']['input'];
};

export type Config = {
  __typename?: 'Config';
  data: Scalars['JSON']['output'];
  key: Scalars['String']['output'];
};

export type ConfigNotification = {
  __typename?: 'ConfigNotification';
  ab: Scalars['Boolean']['output'];
  email: ConfigNotificationEmail;
  interval: Scalars['String']['output'];
  push: ConfigNotificationPush;
  send: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ConfigNotificationData = {
  __typename?: 'ConfigNotificationData';
  CoachPlayerJoined: ConfigNotification;
  MainCoachFirstTeamCreated: ConfigNotification;
  MainCoachNoPlayersCreated: ConfigNotification;
  MainCoachNoTeamCreated: ConfigNotification;
  MainCoachTeamCreated: ConfigNotification;
  MainCoachThreePlayersJoined: ConfigNotification;
  TeamFileCreated: ConfigNotification;
};

export type ConfigNotificationEmail = {
  __typename?: 'ConfigNotificationEmail';
  altText: Scalars['String']['output'];
  altTitle: Scalars['String']['output'];
  send: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ConfigNotificationPush = {
  __typename?: 'ConfigNotificationPush';
  altText: Scalars['String']['output'];
  altTitle: Scalars['String']['output'];
  send: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ConversationUnreadMessageCount = {
  __typename?: 'ConversationUnreadMessageCount';
  conversationId: Scalars['Int']['output'];
  teamId: Scalars['String']['output'];
  unreadMessageCount: Scalars['Int']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeleteCommentResponse = {
  __typename?: 'DeleteCommentResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteEmojiResponse = {
  __typename?: 'DeleteEmojiResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTeamEventResponse = {
  __typename?: 'DeleteTeamEventResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTeamFileResponse = {
  __typename?: 'DeleteTeamFileResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTeamPlayerResponse = {
  __typename?: 'DeleteTeamPlayerResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Emoji = {
  __typename?: 'Emoji';
  createdAt: Scalars['DateTime']['output'];
  emoji: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  name: Scalars['String']['output'];
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type EmojiInput = {
  emoji: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EventConfirmationInput = {
  eventId: Scalars['String']['input'];
  going: Scalars['Boolean']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type EventPushData = {
  __typename?: 'EventPushData';
  eventId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
};

export enum EventType {
  CoachPractice = 'COACH_PRACTICE',
  CustomEvent = 'CUSTOM_EVENT',
  Game = 'GAME',
  Practice = 'PRACTICE'
}

export type FeedPost = {
  __typename?: 'FeedPost';
  archived: Scalars['Boolean']['output'];
  bookmarkCount: Scalars['Int']['output'];
  category: FeedPostCategory;
  commentCount: Scalars['Int']['output'];
  contentSource: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  likeCount: Scalars['Int']['output'];
  seenCount: Scalars['Int']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export enum FeedPostCategory {
  InjuryAndNutrition = 'INJURY_AND_NUTRITION',
  KeepDreaming = 'KEEP_DREAMING',
  Professionals = 'PROFESSIONALS',
  SoccerNow = 'SOCCER_NOW',
  TacticalEdge = 'TACTICAL_EDGE',
  TheSpotlight = 'THE_SPOTLIGHT',
  TipsAndTricks = 'TIPS_AND_TRICKS',
  TrainingAdvice = 'TRAINING_ADVICE',
  TrendingNow = 'TRENDING_NOW',
  Tst_2024 = 'TST_2024',
  UltrainInsider = 'ULTRAIN_INSIDER'
}

export type FeedPostFilter = {
  category?: InputMaybe<FeedPostCategory>;
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type FeedPostInput = {
  category: FeedPostCategory;
  contentSource: Scalars['String']['input'];
  description: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
};

export type FeedPostPushData = {
  __typename?: 'FeedPostPushData';
  feedPostId: Scalars['String']['output'];
};

export type FilterConditionInput = {
  field: Scalars['String']['input'];
  operator: FilterOperator;
  value: Scalars['JSON']['input'];
};

export enum FilterOperator {
  Between = 'BETWEEN',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  In = 'IN',
  IsNull = 'IS_NULL',
  Like = 'LIKE',
  Lt = 'LT',
  Lte = 'LTE',
  Ne = 'NE',
  NotBetween = 'NOT_BETWEEN',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE',
  NotNull = 'NOT_NULL'
}

export type LastActivity = {
  __typename?: 'LastActivity';
  date?: Maybe<Scalars['DateTime']['output']>;
};

export type Like = {
  __typename?: 'Like';
  bookmarked: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  liked: Scalars['Boolean']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  userId: Scalars['String']['output'];
};

export type LikeInput = {
  bookmarked: Scalars['Boolean']['input'];
  liked: Scalars['Boolean']['input'];
};

export enum LineupConfiguration {
  FiveFourOne = 'FIVE_FOUR_ONE',
  FiveThreeTwo = 'FIVE_THREE_TWO',
  FourFiveOne = 'FOUR_FIVE_ONE',
  FourFourTwo = 'FOUR_FOUR_TWO',
  FourOneTwoOneTwo = 'FOUR_ONE_TWO_ONE_TWO',
  FourThreeThree = 'FOUR_THREE_THREE',
  FourTwoThreeOne = 'FOUR_TWO_THREE_ONE',
  OneTwoTwo = 'ONE_TWO_TWO',
  ThreeFiveTwo = 'THREE_FIVE_TWO',
  ThreeFourThree = 'THREE_FOUR_THREE',
  ThreeFourTwoOne = 'THREE_FOUR_TWO_ONE',
  ThreeTwoOne = 'THREE_TWO_ONE',
  TwoOneTwo = 'TWO_ONE_TWO',
  TwoThreeOne = 'TWO_THREE_ONE',
  TwoTwoOne = 'TWO_TWO_ONE'
}

export type LineupPushData = {
  __typename?: 'LineupPushData';
  lineupId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
};

export type LiveStream = {
  __typename?: 'LiveStream';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  jwtToken?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  zoomId?: Maybe<Scalars['String']['output']>;
  zoomPassword?: Maybe<Scalars['String']['output']>;
};

export type LiveStreamInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  imageUrl: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  zoomId?: InputMaybe<Scalars['String']['input']>;
  zoomPassword?: InputMaybe<Scalars['String']['input']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type MessagePushData = {
  __typename?: 'MessagePushData';
  coachUserId: Scalars['String']['output'];
  count: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ModifyUserInput = {
  androidPushToken?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  iosPushToken?: InputMaybe<Scalars['String']['input']>;
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isContentCreator?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  proTeamId?: InputMaybe<Scalars['Int']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEventConfirmation: TeamEventsPlayers;
  addProTeam: ProTeam;
  addTeamEventsPlayer: TeamEventsPlayers;
  adminModifyUser: User;
  answer: UserAnswer;
  changePassword: Scalars['Boolean']['output'];
  comment: Comment;
  confirmSmsVerification: Scalars['Boolean']['output'];
  createActivity: Activity;
  createActivityPlan: ActivityPlan;
  createChatMessage: ChatMessage;
  createCollection: Collection;
  createFeedPost: FeedPost;
  createLiveStream: LiveStream;
  createPoll: Poll;
  createTeam: Team;
  createTeamAnnouncement: Array<Maybe<TeamAnnouncement>>;
  createTeamEvent: TeamEvent;
  createTeamFile: TeamFile;
  createTeamLineup: TeamLineup;
  createTeamPlayer: TeamPlayer;
  deleteAccount: Scalars['Boolean']['output'];
  deleteActivity: DeleteResponse;
  deleteActivityPlan: DeleteResponse;
  deleteCollection: DeleteResponse;
  deleteComment: DeleteCommentResponse;
  deleteEmoji: DeleteEmojiResponse;
  deleteFeedPost: DeleteResponse;
  deletePoll: DeleteResponse;
  deleteTeamEvent: DeleteTeamEventResponse;
  deleteTeamFile: DeleteTeamFileResponse;
  deleteTeamPlayer: DeleteTeamPlayerResponse;
  emoji: Emoji;
  forgotPasswordRequest: Scalars['Boolean']['output'];
  givePlayersInstructions: PlayersInstructionsResponse;
  like: Like;
  login: LoginResponse;
  modifyUser: User;
  ping: Scalars['String']['output'];
  register: LoginResponse;
  remindAboutEvent: Scalars['Int']['output'];
  report: Report;
  resetPassword: Scalars['Boolean']['output'];
  saveItem: SavedItem;
  seen: Seen;
  sendPushAboutFeed: Scalars['Int']['output'];
  sendPushToAll: Scalars['Int']['output'];
  sendSmsVerification: Scalars['Boolean']['output'];
  tryCode: TeamPlayer;
  unsaveItem: DeleteResponse;
  updateActivity: Activity;
  updateActivityPlan: ActivityPlan;
  updateCollection: Collection;
  updateFeedPost: FeedPost;
  updateTeam: Team;
  updateTeamAnnouncement: TeamAnnouncement;
  updateTeamEvent: TeamEvent;
  updateTeamFile: TeamFile;
  updateTeamLineup: TeamLineup;
  updateTeamPlayer: TeamPlayer;
  validateUser: Scalars['Boolean']['output'];
};


export type MutationAddEventConfirmationArgs = {
  input: EventConfirmationInput;
};


export type MutationAddProTeamArgs = {
  input: ProTeamInput;
};


export type MutationAddTeamEventsPlayerArgs = {
  input: TeamEventsPlayerInput;
};


export type MutationAdminModifyUserArgs = {
  modifyUserInput: AdminModifyUserInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAnswerArgs = {
  answerId: Scalars['Int']['input'];
};


export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCommentArgs = {
  commentInput: CommentInput;
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};


export type MutationConfirmSmsVerificationArgs = {
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationCreateActivityArgs = {
  input: ActivityInput;
};


export type MutationCreateActivityPlanArgs = {
  input: ActivityPlanInput;
};


export type MutationCreateChatMessageArgs = {
  chatMessageInput: ChatMessageInput;
};


export type MutationCreateCollectionArgs = {
  input: CollectionInput;
};


export type MutationCreateFeedPostArgs = {
  input: FeedPostInput;
};


export type MutationCreateLiveStreamArgs = {
  liveStreamInput: LiveStreamInput;
};


export type MutationCreatePollArgs = {
  input: PollInput;
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};


export type MutationCreateTeamArgs = {
  input: TeamInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTeamAnnouncementArgs = {
  input: TeamAnnouncementInput;
};


export type MutationCreateTeamEventArgs = {
  input: TeamEventInput;
};


export type MutationCreateTeamFileArgs = {
  input: TeamFileInput;
};


export type MutationCreateTeamLineupArgs = {
  input: TeamLineupInput;
};


export type MutationCreateTeamPlayerArgs = {
  input: TeamPlayerInput;
};


export type MutationDeleteAccountArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteActivityPlanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmojiArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFeedPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePollArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationDeleteTeamFileArgs = {
  videoId: Scalars['String']['input'];
};


export type MutationDeleteTeamPlayerArgs = {
  id: Scalars['String']['input'];
};


export type MutationEmojiArgs = {
  emojiInput: EmojiInput;
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};


export type MutationForgotPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGivePlayersInstructionsArgs = {
  input: PlayersInstructionsInput;
};


export type MutationLikeArgs = {
  likeInput: LikeInput;
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  isPassword?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationModifyUserArgs = {
  modifyUserInput: ModifyUserInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterArgs = {
  userInput: UserInput;
};


export type MutationRemindAboutEventArgs = {
  eventId: Scalars['Int']['input'];
  users: Array<Scalars['String']['input']>;
};


export type MutationReportArgs = {
  modelId: Scalars['String']['input'];
  modelType: Scalars['String']['input'];
  reportInput: ReportInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type MutationSaveItemArgs = {
  input: SavedItemInput;
};


export type MutationSeenArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};


export type MutationSendPushAboutFeedArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendPushToAllArgs = {
  ids?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendSmsVerificationArgs = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber: Scalars['String']['input'];
};


export type MutationTryCodeArgs = {
  code: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnsaveItemArgs = {
  input: SavedItemInput;
};


export type MutationUpdateActivityArgs = {
  id: Scalars['ID']['input'];
  input: ActivityUpdateInput;
};


export type MutationUpdateActivityPlanArgs = {
  id: Scalars['ID']['input'];
  input: ActivityPlanUpdateInput;
};


export type MutationUpdateCollectionArgs = {
  id: Scalars['ID']['input'];
  input: CollectionUpdateInput;
};


export type MutationUpdateFeedPostArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFeedPostInput;
};


export type MutationUpdateTeamArgs = {
  id: Scalars['String']['input'];
  input: UpdateTeamInput;
};


export type MutationUpdateTeamAnnouncementArgs = {
  id: Scalars['Int']['input'];
  input: UpdateTeamAnnouncementInput;
};


export type MutationUpdateTeamEventArgs = {
  changeNextEvents?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  input: UpdateTeamEventInput;
};


export type MutationUpdateTeamFileArgs = {
  id: Scalars['String']['input'];
  input: UpdateTeamFileInput;
};


export type MutationUpdateTeamLineupArgs = {
  id: Scalars['String']['input'];
  input: TeamLineupUpdateInput;
};


export type MutationUpdateTeamPlayerArgs = {
  id: Scalars['String']['input'];
  input: UpdateTeamPlayerInput;
};


export type MutationValidateUserArgs = {
  data: ValidateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  archived: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  modelId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['Date']['output']>;
  sentAt?: Maybe<Scalars['Date']['output']>;
  serviceType: NotificationServiceType;
  status: NotificationStatus;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId: Scalars['String']['output'];
};

export type NotificationInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelType?: InputMaybe<Scalars['String']['input']>;
  sendAt?: InputMaybe<Scalars['Date']['input']>;
  sentAt?: InputMaybe<Scalars['Date']['input']>;
  serviceType?: InputMaybe<NotificationServiceType>;
  status?: InputMaybe<NotificationStatus>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NotificationType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum NotificationModelType {
  Team = 'TEAM',
  TeamFile = 'TEAM_FILE',
  TeamPlayer = 'TEAM_PLAYER',
  User = 'USER'
}

export enum NotificationServiceType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS'
}

export enum NotificationStatus {
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export enum NotificationType {
  CoachPlayerJoined = 'CoachPlayerJoined',
  MainCoachFirstTeamCreated = 'MainCoachFirstTeamCreated',
  MainCoachNoPlayersCreated = 'MainCoachNoPlayersCreated',
  MainCoachNoTeamCreated = 'MainCoachNoTeamCreated',
  MainCoachTeamCreated = 'MainCoachTeamCreated',
  MainCoachThreePlayersJoined = 'MainCoachThreePlayersJoined',
  TeamFileCreated = 'TeamFileCreated'
}

export type OrderBy = {
  column: Scalars['String']['input'];
  direction?: InputMaybe<OrderByDirection>;
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type PlayersInstructionsInput = {
  teamId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type PlayersInstructionsResponse = {
  __typename?: 'PlayersInstructionsResponse';
  teamId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  userIds: Array<Maybe<Scalars['String']['output']>>;
};

export type Poll = {
  __typename?: 'Poll';
  answers: Array<Answer>;
  archived: Scalars['Boolean']['output'];
  commentCount: Scalars['Int']['output'];
  commentDisabled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  question: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userAnswerCount: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type PollInput = {
  answers: Array<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

export type PollPushData = {
  __typename?: 'PollPushData';
  pollId: Scalars['String']['output'];
  teamId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum Position {
  Attacker = 'ATTACKER',
  Defender = 'DEFENDER',
  Goalkeeper = 'GOALKEEPER',
  Midfielder = 'MIDFIELDER'
}

export type ProTeam = {
  __typename?: 'ProTeam';
  archived: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProTeamInput = {
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  coach: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isAdult?: Maybe<Scalars['Boolean']['output']>;
  isContentCreator?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  playerNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  proTeam?: Maybe<ProTeam>;
  role?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type Push = {
  __typename?: 'Push';
  badge?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum PushTokenStatus {
  Default = 'default',
  Invalid = 'invalid',
  Valid = 'valid'
}

export enum PushTopic {
  Allusers = 'allusers',
  Stageallusers = 'stageallusers'
}

export type Query = {
  __typename?: 'Query';
  activities: Array<Activity>;
  activity: Activity;
  activityPlan: ActivityPlan;
  activityPlans: Array<ActivityPlan>;
  adminFeedPosts: Array<FeedPost>;
  adminLiveStreams: Array<LiveStream>;
  adminMetrics: AdminMetrics;
  adminTeams: Array<Team>;
  adminUsers: Array<User>;
  allowedAuthMethods: AuthMethodsConfig;
  coachChat: ChatConversation;
  collection: Collection;
  collections: Array<Collection>;
  comments: Array<Comment>;
  conversationUnreadMessageCount: UnreadMessageCount;
  emojis: Array<Emoji>;
  feedPost: FeedPost;
  feedPostPage: Scalars['Int']['output'];
  feedPosts: Array<FeedPost>;
  likes: Array<Like>;
  liveStream: LiveStream;
  liveStreams: Array<LiveStream>;
  me?: Maybe<LoginResponse>;
  parentChat: ChatConversation;
  playerChat: ChatConversation;
  privateChat: ChatConversation;
  proTeams: Array<ProTeam>;
  savedItems: Array<SavedItem>;
  savedPosts: Array<FeedPost>;
  seens: Array<Seen>;
  signUrl: S3SignResponse;
  team: Team;
  teamAnnouncements: Array<TeamAnnouncement>;
  teamChat: ChatConversation;
  teamCurrentLineup?: Maybe<TeamLineup>;
  teamEvent: TeamEvent;
  teamEventTypes: Array<TeamEventType>;
  teamEvents: Array<TeamEvent>;
  teamEventsPlayers: Array<TeamEventsPlayers>;
  teamFiles: Array<TeamFile>;
  teamLineup: TeamLineup;
  teamMembers: Array<TeamMembers>;
  teamNameByCode: TeamName;
  teamPlayers: Array<PublicUser>;
  teams: Array<Team>;
  user: PublicUser;
  userLiked?: Maybe<Like>;
  userTeamEvents: Array<TeamEventsPlayers>;
  version: Scalars['String']['output'];
};


export type QueryActivitiesArgs = {
  filters?: InputMaybe<Array<FilterConditionInput>>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryActivityArgs = {
  activityId: Scalars['Int']['input'];
};


export type QueryActivityPlanArgs = {
  activityPlanId: Scalars['Int']['input'];
};


export type QueryActivityPlansArgs = {
  filters?: InputMaybe<Array<FilterConditionInput>>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryAdminFeedPostsArgs = {
  category: FeedPostCategory;
  pagination?: InputMaybe<Pagination>;
};


export type QueryAdminLiveStreamsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryAdminTeamsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryAdminUsersArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryAllowedAuthMethodsArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<AuthMethodsConfigInput>;
  phoneOrEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCoachChatArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryCollectionArgs = {
  collectionId: Scalars['Int']['input'];
};


export type QueryCollectionsArgs = {
  filters?: InputMaybe<Array<FilterConditionInput>>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCommentsArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
  pagination?: InputMaybe<Pagination>;
};


export type QueryEmojisArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFeedPostArgs = {
  feedPostId: Scalars['Int']['input'];
};


export type QueryFeedPostPageArgs = {
  category?: InputMaybe<FeedPostCategory>;
  feedPostId: Scalars['Int']['input'];
  filter?: InputMaybe<FeedPostFilter>;
  pagination: Pagination;
};


export type QueryFeedPostsArgs = {
  category?: InputMaybe<FeedPostCategory>;
  filter?: InputMaybe<FeedPostFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryLikesArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
  pagination?: InputMaybe<Pagination>;
};


export type QueryLiveStreamArgs = {
  liveStreamId: Scalars['Int']['input'];
};


export type QueryLiveStreamsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryParentChatArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryPlayerChatArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryPrivateChatArgs = {
  teamId: Scalars['String']['input'];
  userId1: Scalars['String']['input'];
  userId2: Scalars['String']['input'];
};


export type QuerySavedItemsArgs = {
  filters?: InputMaybe<Array<FilterConditionInput>>;
  pagination?: InputMaybe<Pagination>;
};


export type QuerySavedPostsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySeensArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
  pagination?: InputMaybe<Pagination>;
};


export type QuerySignUrlArgs = {
  contentType: Scalars['String']['input'];
  objectName: Scalars['String']['input'];
};


export type QueryTeamArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryTeamAnnouncementsArgs = {
  pagination?: InputMaybe<Pagination>;
  teamId: Scalars['String']['input'];
};


export type QueryTeamChatArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryTeamCurrentLineupArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryTeamEventArgs = {
  eventId: Scalars['Int']['input'];
};


export type QueryTeamEventsArgs = {
  pagination?: InputMaybe<Pagination>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teamId: Array<Scalars['String']['input']>;
};


export type QueryTeamEventsPlayersArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTeamFilesArgs = {
  pagination?: InputMaybe<Pagination>;
  teamId: Scalars['String']['input'];
};


export type QueryTeamLineupArgs = {
  teamLineupId: Scalars['Int']['input'];
};


export type QueryTeamMembersArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryTeamNameByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryTeamPlayersArgs = {
  teamId: Scalars['String']['input'];
};


export type QueryTeamsArgs = {
  pagination?: InputMaybe<Pagination>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryUserLikedArgs = {
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserTeamEventsArgs = {
  userId: Scalars['String']['input'];
};

export enum ReminderReceiverType {
  All = 'All',
  NotResponded = 'NotResponded'
}

export enum ReminderType {
  High = 'High',
  Low = 'Low',
  Moderate = 'Moderate'
}

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type ReportInput = {
  text: Scalars['String']['input'];
};

export type S3SignResponse = {
  __typename?: 'S3SignResponse';
  fileKey: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  publicUrl: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SavedItem = {
  __typename?: 'SavedItem';
  archived: Scalars['Boolean']['output'];
  collectionId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type SavedItemInput = {
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  modelId: Scalars['String']['input'];
  modelType: SocialModelType;
};

export type SavedItemUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Seen = {
  __typename?: 'Seen';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  modelId: Scalars['String']['output'];
  modelType: SocialModelType;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export enum SocialModelType {
  Activity = 'ACTIVITY',
  Announcement = 'ANNOUNCEMENT',
  FeedPost = 'FEED_POST',
  Poll = 'POLL',
  Team = 'TEAM',
  TeamFile = 'TEAM_FILE'
}

export enum SocialPostType {
  AddCollection = 'ADD_COLLECTION',
  AddWishlist = 'ADD_WISHLIST',
  ProductReview = 'PRODUCT_REVIEW'
}

export type Team = {
  __typename?: 'Team';
  archived: Scalars['Boolean']['output'];
  coach: PublicUser;
  code: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isHidden: Scalars['Boolean']['output'];
  lastActivity?: Maybe<LastActivity>;
  lineup?: Maybe<TeamLineup>;
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  playerCount: Scalars['Int']['output'];
  polls: Array<Poll>;
  publicImageUrl?: Maybe<Scalars['String']['output']>;
  seasonEnd?: Maybe<Scalars['DateTime']['output']>;
  seasonStart?: Maybe<Scalars['DateTime']['output']>;
  slogan?: Maybe<Scalars['String']['output']>;
  teamAnnouncements: Array<TeamAnnouncement>;
  teamEvents: Array<TeamEvent>;
  teamFiles: Array<TeamFile>;
  teamLineupId?: Maybe<Scalars['Int']['output']>;
  type: TeamType;
  userId: Scalars['String']['output'];
  userRoles: UserRolesInTeam;
};

export type TeamAnnouncement = {
  __typename?: 'TeamAnnouncement';
  archived: Scalars['Boolean']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emojiCount: Scalars['Int']['output'];
  files: Array<Maybe<TeamFile>>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  poll?: Maybe<Poll>;
  seenCount: Scalars['Int']['output'];
  teamId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type TeamAnnouncementInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  teamId: Array<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TeamEvent = {
  __typename?: 'TeamEvent';
  archived: Scalars['Boolean']['output'];
  arriveEarly?: Maybe<Scalars['String']['output']>;
  away?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  ends: Scalars['DateTime']['output'];
  groupId?: Maybe<Scalars['Int']['output']>;
  home?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  location: Scalars['String']['output'];
  locationDetails?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  recurring: TeamEventRecurring;
  recurringUntilDate?: Maybe<Scalars['DateTime']['output']>;
  reminder: TeamEventReminder;
  reminderNotRespondedType?: Maybe<ReminderType>;
  reminderType?: Maybe<ReminderType>;
  status: TeamEventStatus;
  subtype?: Maybe<TeamEventType>;
  subtypeId?: Maybe<Scalars['Int']['output']>;
  team: Team;
  teamId: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
  type: EventType;
  undecided?: Maybe<Scalars['Boolean']['output']>;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type TeamEventInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  arriveEarly?: InputMaybe<Scalars['String']['input']>;
  away?: InputMaybe<Scalars['Boolean']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  ends: Scalars['DateTime']['input'];
  home?: InputMaybe<Scalars['Boolean']['input']>;
  location: Scalars['String']['input'];
  locationDetails?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  opponent?: InputMaybe<Scalars['String']['input']>;
  recurring: TeamEventRecurring;
  recurringUntilDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminder: TeamEventReminder;
  reminderNotRespondedType?: InputMaybe<ReminderType>;
  reminderType?: InputMaybe<ReminderType>;
  status: TeamEventStatus;
  subtypeId?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  type: EventType;
  undecided?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TeamEventRecurring {
  Every2Days = 'Every2Days',
  EveryDay = 'EveryDay',
  EveryMonth = 'EveryMonth',
  EveryWeek = 'EveryWeek',
  EveryYear = 'EveryYear',
  None = 'None'
}

export enum TeamEventReminder {
  AtTimeOfEvent = 'AtTimeOfEvent',
  FifteenMinutesBefore = 'FifteenMinutesBefore',
  FiveDaysBefore = 'FiveDaysBefore',
  FiveMinutesBefore = 'FiveMinutesBefore',
  None = 'None',
  OneDayBefore = 'OneDayBefore',
  OneHourBefore = 'OneHourBefore',
  TenDaysBefore = 'TenDaysBefore',
  TenMinutesBefore = 'TenMinutesBefore',
  ThirtyMinutesBefore = 'ThirtyMinutesBefore',
  ThreeDaysBefore = 'ThreeDaysBefore',
  TwoDaysBefore = 'TwoDaysBefore',
  TwoHoursBefore = 'TwoHoursBefore'
}

export enum TeamEventStatus {
  Cancelled = 'CANCELLED',
  Delayed = 'DELAYED',
  None = 'None',
  Postponed = 'POSTPONED'
}

export type TeamEventType = {
  __typename?: 'TeamEventType';
  archived: Scalars['Boolean']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  text_icon?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: EventType;
  updatedAt: Scalars['DateTime']['output'];
};

export type TeamEventsPlayerInput = {
  eventId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type TeamEventsPlayers = {
  __typename?: 'TeamEventsPlayers';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventId: Scalars['String']['output'];
  going: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type TeamFile = {
  __typename?: 'TeamFile';
  archived: Scalars['Boolean']['output'];
  commentCount: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  likeCount: Scalars['Int']['output'];
  modelId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<SocialModelType>;
  seenCount: Scalars['Int']['output'];
  teamId?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: TeamFileType;
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type TeamFileInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelType?: InputMaybe<SocialModelType>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: TeamFileType;
};

export enum TeamFileType {
  Audio = 'audio',
  File = 'file',
  Image = 'image',
  Video = 'video'
}

export type TeamInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isHidden: Scalars['Boolean']['input'];
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  seasonEnd?: InputMaybe<Scalars['DateTime']['input']>;
  seasonStart?: InputMaybe<Scalars['DateTime']['input']>;
  slogan?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TeamType>;
};

export type TeamLineup = {
  __typename?: 'TeamLineup';
  archived: Scalars['Boolean']['output'];
  benchPlayerId1?: Maybe<Scalars['String']['output']>;
  benchPlayerId2?: Maybe<Scalars['String']['output']>;
  benchPlayerId3?: Maybe<Scalars['String']['output']>;
  benchPlayerId4?: Maybe<Scalars['String']['output']>;
  benchPlayerId5?: Maybe<Scalars['String']['output']>;
  benchPlayerId6?: Maybe<Scalars['String']['output']>;
  benchPlayerId7?: Maybe<Scalars['String']['output']>;
  benchPlayerId8?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lineupConfiguration: LineupConfiguration;
  playerId1?: Maybe<Scalars['String']['output']>;
  playerId2?: Maybe<Scalars['String']['output']>;
  playerId3?: Maybe<Scalars['String']['output']>;
  playerId4?: Maybe<Scalars['String']['output']>;
  playerId5?: Maybe<Scalars['String']['output']>;
  playerId6?: Maybe<Scalars['String']['output']>;
  playerId7?: Maybe<Scalars['String']['output']>;
  playerId8?: Maybe<Scalars['String']['output']>;
  playerId9?: Maybe<Scalars['String']['output']>;
  playerId10?: Maybe<Scalars['String']['output']>;
  playerId11?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Scalars['JSON']['output']>;
  screenshotUrl?: Maybe<Scalars['String']['output']>;
  shirtSet?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  theme?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type TeamLineupInput = {
  archived: Scalars['Boolean']['input'];
  benchPlayerId1?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId2?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId3?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId4?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId5?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId6?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId7?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId8?: InputMaybe<Scalars['String']['input']>;
  lineupConfiguration: LineupConfiguration;
  playerId1?: InputMaybe<Scalars['String']['input']>;
  playerId2?: InputMaybe<Scalars['String']['input']>;
  playerId3?: InputMaybe<Scalars['String']['input']>;
  playerId4?: InputMaybe<Scalars['String']['input']>;
  playerId5?: InputMaybe<Scalars['String']['input']>;
  playerId6?: InputMaybe<Scalars['String']['input']>;
  playerId7?: InputMaybe<Scalars['String']['input']>;
  playerId8?: InputMaybe<Scalars['String']['input']>;
  playerId9?: InputMaybe<Scalars['String']['input']>;
  playerId10?: InputMaybe<Scalars['String']['input']>;
  playerId11?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Scalars['JSON']['input']>;
  screenshotUrl?: InputMaybe<Scalars['String']['input']>;
  shirtSet?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
  theme?: InputMaybe<Scalars['String']['input']>;
};

export type TeamLineupUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  benchPlayerId1?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId2?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId3?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId4?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId5?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId6?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId7?: InputMaybe<Scalars['String']['input']>;
  benchPlayerId8?: InputMaybe<Scalars['String']['input']>;
  lineupConfiguration?: InputMaybe<LineupConfiguration>;
  playerId1?: InputMaybe<Scalars['String']['input']>;
  playerId2?: InputMaybe<Scalars['String']['input']>;
  playerId3?: InputMaybe<Scalars['String']['input']>;
  playerId4?: InputMaybe<Scalars['String']['input']>;
  playerId5?: InputMaybe<Scalars['String']['input']>;
  playerId6?: InputMaybe<Scalars['String']['input']>;
  playerId7?: InputMaybe<Scalars['String']['input']>;
  playerId8?: InputMaybe<Scalars['String']['input']>;
  playerId9?: InputMaybe<Scalars['String']['input']>;
  playerId10?: InputMaybe<Scalars['String']['input']>;
  playerId11?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Scalars['JSON']['input']>;
  screenshotUrl?: InputMaybe<Scalars['String']['input']>;
  shirtSet?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMembers = {
  __typename?: 'TeamMembers';
  archived: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  improvement?: Maybe<Scalars['String']['output']>;
  isAssistant: Scalars['Boolean']['output'];
  isCaptain: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  isPlayer: Scalars['Boolean']['output'];
  isViceCaptain: Scalars['Boolean']['output'];
  level?: Maybe<Scalars['String']['output']>;
  playerNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  status: TeamPlayerStatus;
  team?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<PublicUser>;
  userId: Scalars['String']['output'];
};

export type TeamName = {
  __typename?: 'TeamName';
  name: Scalars['String']['output'];
};

export type TeamPlayer = {
  __typename?: 'TeamPlayer';
  archived: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  improvement?: Maybe<Scalars['String']['output']>;
  isAssistant: Scalars['Boolean']['output'];
  isCaptain: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  isPlayer: Scalars['Boolean']['output'];
  isViceCaptain: Scalars['Boolean']['output'];
  level?: Maybe<Scalars['String']['output']>;
  playerNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  status: TeamPlayerStatus;
  team?: Maybe<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type TeamPlayerInput = {
  archived: Scalars['Boolean']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  improvement?: InputMaybe<Scalars['String']['input']>;
  isAssistant: Scalars['Boolean']['input'];
  isCaptain?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach: Scalars['Boolean']['input'];
  isParent?: InputMaybe<Scalars['Boolean']['input']>;
  isPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  isViceCaptain?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  playerNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  status?: InputMaybe<TeamPlayerStatus>;
  team?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum TeamPlayerStatus {
  Available = 'AVAILABLE',
  Injured = 'INJURED',
  NotAvailable = 'NOT_AVAILABLE'
}

export type TeamPushData = {
  __typename?: 'TeamPushData';
  teamId: Scalars['String']['output'];
};

export enum TeamType {
  Community = 'community',
  Team = 'team'
}

export type UnreadMessageCount = {
  __typename?: 'UnreadMessageCount';
  conversationsUnreadMessageCount?: Maybe<Array<Maybe<ConversationUnreadMessageCount>>>;
  totalUnreadMessageCount: Scalars['Int']['output'];
};

export type UpdateFeedPostInput = {
  description: Scalars['String']['input'];
};

export type UpdateTeamAnnouncementInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamEventInput = {
  arriveEarly?: InputMaybe<Scalars['String']['input']>;
  away?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  ends?: InputMaybe<Scalars['DateTime']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['Boolean']['input']>;
  location: Scalars['String']['input'];
  locationDetails?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  opponent?: InputMaybe<Scalars['String']['input']>;
  recurring?: InputMaybe<TeamEventRecurring>;
  recurringUntilDate?: InputMaybe<Scalars['DateTime']['input']>;
  reminder: TeamEventReminder;
  reminderNotRespondedType?: InputMaybe<ReminderType>;
  reminderType?: InputMaybe<ReminderType>;
  status?: InputMaybe<TeamEventStatus>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  undecided?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateTeamFileInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isHidden: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  seasonEnd?: InputMaybe<Scalars['DateTime']['input']>;
  seasonStart?: InputMaybe<Scalars['DateTime']['input']>;
  slogan?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamPlayerInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  improvement?: InputMaybe<Scalars['String']['input']>;
  isAssistant?: InputMaybe<Scalars['Boolean']['input']>;
  isCaptain?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isParent?: InputMaybe<Scalars['Boolean']['input']>;
  isPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  isViceCaptain?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  playerNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  status?: InputMaybe<TeamPlayerStatus>;
  team?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean']['output'];
  androidPushToken?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  coach: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  iosPushToken?: Maybe<Scalars['String']['output']>;
  isAdult?: Maybe<Scalars['Boolean']['output']>;
  isContentCreator?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  newsletter: Scalars['Boolean']['output'];
  paymentToken?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  proTeam?: Maybe<ProTeam>;
  proTeamId?: Maybe<Scalars['Int']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  pushTokenStatus?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type UserAnswer = {
  __typename?: 'UserAnswer';
  answerId: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  pollId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: PublicUser;
  userId: Scalars['String']['output'];
};

export type UserInput = {
  coach?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isContentCreator?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  proTeamId?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserRolesInTeam = {
  __typename?: 'UserRolesInTeam';
  isAssistant: Scalars['Boolean']['output'];
  isCaptain: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isCoachOrAssistant: Scalars['Boolean']['output'];
  isMainCoach: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  isPlayer: Scalars['Boolean']['output'];
  isViceCaptain: Scalars['Boolean']['output'];
};

export type ValidateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SignUrlQueryVariables = Exact<{
  objectName: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;


export type SignUrlQuery = { __typename?: 'Query', signUrl: { __typename?: 'S3SignResponse', signedUrl: string, publicUrl: string, fileName: string, fileKey: string } };

export type AdminMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminMetricsQuery = { __typename?: 'Query', adminMetrics: { __typename?: 'AdminMetrics', userCount: number, feedPostCount: number, teamCount: number, liveStreamCount: number } };

export type AdminUsersQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type AdminUsersQuery = { __typename?: 'Query', adminUsers: Array<{ __typename?: 'User', id: string, email?: string | undefined, phoneNumber?: string | undefined, fullName: string, admin: boolean, coach: boolean, location?: string | undefined, imageUrl?: string | undefined, position?: Position | undefined, verified: boolean, role?: string | undefined, timeZone?: string | undefined, archived: boolean, createdAt?: any | undefined }> };

export type AdminFeedPostsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  category: FeedPostCategory;
}>;


export type AdminFeedPostsQuery = { __typename?: 'Query', adminFeedPosts: Array<{ __typename?: 'FeedPost', id: number, userId: string, imageUrl: string, category: FeedPostCategory, contentSource: string, description: string, createdAt?: any | undefined, archived: boolean, likeCount: number, commentCount: number, bookmarkCount: number, user: { __typename?: 'PublicUser', id: string, fullName: string } }> };

export type CreateFeedPostMutationVariables = Exact<{
  input: FeedPostInput;
}>;


export type CreateFeedPostMutation = { __typename?: 'Mutation', createFeedPost: { __typename?: 'FeedPost', id: number } };

export type LiveStreamQueryVariables = Exact<{
  liveStreamId: Scalars['Int']['input'];
}>;


export type LiveStreamQuery = { __typename?: 'Query', liveStream: { __typename?: 'LiveStream', id: number, imageUrl: string, zoomId?: string | undefined, zoomPassword?: string | undefined, startDate: any, endDate: any, title: string, description: string, archived: boolean, createdAt: any, jwtToken?: string | undefined } };

export type AdminLiveStreamsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type AdminLiveStreamsQuery = { __typename?: 'Query', adminLiveStreams: Array<{ __typename?: 'LiveStream', id: number, imageUrl: string, zoomId?: string | undefined, startDate: any, endDate: any, title: string, description: string, archived: boolean, createdAt: any }> };

export type CreateLiveStreamMutationVariables = Exact<{
  liveStreamInput: LiveStreamInput;
}>;


export type CreateLiveStreamMutation = { __typename?: 'Mutation', createLiveStream: { __typename?: 'LiveStream', id: number } };

export type ProTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProTeamsQuery = { __typename?: 'Query', proTeams: Array<{ __typename?: 'ProTeam', id: number, imageUrl: string, name: string, archived: boolean }> };

export type AddProTeamMutationVariables = Exact<{
  input: ProTeamInput;
}>;


export type AddProTeamMutation = { __typename?: 'Mutation', addProTeam: { __typename?: 'ProTeam', id: number } };

export type AdminTeamsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type AdminTeamsQuery = { __typename?: 'Query', adminTeams: Array<{ __typename?: 'Team', id: string, name: string, code: string, location: string, createdAt?: any | undefined, playerCount: number, archived: boolean, coach: { __typename?: 'PublicUser', id: string, fullName: string } }> };

export type TeamNameByCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type TeamNameByCodeQuery = { __typename?: 'Query', teamNameByCode: { __typename?: 'TeamName', name: string } };

export type AddEventConfirmationMutationVariables = Exact<{
  input: EventConfirmationInput;
}>;


export type AddEventConfirmationMutation = { __typename?: 'Mutation', addEventConfirmation: { __typename?: 'TeamEventsPlayers', id: number, userId: string, eventId: string, createdAt?: any | undefined, updatedAt?: any | undefined, going: boolean } };

export type GenericUserFieldsFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined };

export type CurrentUserFieldsFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined } } | undefined };

export type LoginMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined } } };

export type RegisterMutationVariables = Exact<{
  userInput: UserInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined } } };

export type AdminModifyUserMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  modifyUserInput: AdminModifyUserInput;
}>;


export type AdminModifyUserMutation = { __typename?: 'Mutation', adminModifyUser: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, email?: string | undefined, phoneNumber?: string | undefined, admin: boolean, createdAt?: any | undefined } };

export type DeleteFeedPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteFeedPostMutation = { __typename?: 'Mutation', deleteFeedPost: { __typename?: 'DeleteResponse', success?: boolean | undefined, message?: string | undefined } };

export type UpdateFeedPostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateFeedPostInput;
}>;


export type UpdateFeedPostMutation = { __typename?: 'Mutation', updateFeedPost: { __typename?: 'FeedPost', id: number, imageUrl: string, category: FeedPostCategory, contentSource: string, description: string, createdAt?: any | undefined, archived: boolean, likeCount: number, commentCount: number, bookmarkCount: number } };

export type SendPushToAllMutationVariables = Exact<{
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendPushToAllMutation = { __typename?: 'Mutation', sendPushToAll: number };

export type SendPushAboutFeedMutationVariables = Exact<{
  text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SendPushAboutFeedMutation = { __typename?: 'Mutation', sendPushAboutFeed: number };

export type FeedPostQueryVariables = Exact<{
  feedPostId: Scalars['Int']['input'];
}>;


export type FeedPostQuery = { __typename?: 'Query', feedPost: { __typename?: 'FeedPost', id: number, imageUrl: string, thumbnailUrl?: string | undefined, description: string, category: FeedPostCategory } };

export const GenericUserFieldsFragmentDoc = gql`
    fragment GenericUserFields on User {
  id
  firstName
  lastName
  fullName
  email
  phoneNumber
  admin
  createdAt
}
    `;
export const CurrentUserFieldsFragmentDoc = gql`
    fragment CurrentUserFields on User {
  ...GenericUserFields
}
    ${GenericUserFieldsFragmentDoc}`;
export const SignUrlDocument = gql`
    query SignUrl($objectName: String!, $contentType: String!) {
  signUrl(objectName: $objectName, contentType: $contentType) {
    signedUrl
    publicUrl
    fileName
    fileKey
  }
}
    `;

/**
 * __useSignUrlQuery__
 *
 * To run a query within a React component, call `useSignUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignUrlQuery({
 *   variables: {
 *      objectName: // value for 'objectName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useSignUrlQuery(baseOptions: Apollo.QueryHookOptions<SignUrlQuery, SignUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignUrlQuery, SignUrlQueryVariables>(SignUrlDocument, options);
      }
export function useSignUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignUrlQuery, SignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignUrlQuery, SignUrlQueryVariables>(SignUrlDocument, options);
        }
export type SignUrlQueryHookResult = ReturnType<typeof useSignUrlQuery>;
export type SignUrlLazyQueryHookResult = ReturnType<typeof useSignUrlLazyQuery>;
export type SignUrlQueryResult = Apollo.QueryResult<SignUrlQuery, SignUrlQueryVariables>;
export const AdminMetricsDocument = gql`
    query AdminMetrics {
  adminMetrics {
    userCount
    feedPostCount
    teamCount
    liveStreamCount
  }
}
    `;

/**
 * __useAdminMetricsQuery__
 *
 * To run a query within a React component, call `useAdminMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminMetricsQuery(baseOptions?: Apollo.QueryHookOptions<AdminMetricsQuery, AdminMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMetricsQuery, AdminMetricsQueryVariables>(AdminMetricsDocument, options);
      }
export function useAdminMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMetricsQuery, AdminMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMetricsQuery, AdminMetricsQueryVariables>(AdminMetricsDocument, options);
        }
export type AdminMetricsQueryHookResult = ReturnType<typeof useAdminMetricsQuery>;
export type AdminMetricsLazyQueryHookResult = ReturnType<typeof useAdminMetricsLazyQuery>;
export type AdminMetricsQueryResult = Apollo.QueryResult<AdminMetricsQuery, AdminMetricsQueryVariables>;
export const AdminUsersDocument = gql`
    query AdminUsers($pagination: Pagination) {
  adminUsers(pagination: $pagination) {
    id
    email
    phoneNumber
    fullName
    admin
    coach
    location
    imageUrl
    position
    verified
    role
    timeZone
    archived
    createdAt
  }
}
    `;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
      }
export function useAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
        }
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = Apollo.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const AdminFeedPostsDocument = gql`
    query AdminFeedPosts($pagination: Pagination, $category: FeedPostCategory!) {
  adminFeedPosts(category: $category, pagination: $pagination) {
    id
    userId
    imageUrl
    category
    contentSource
    description
    createdAt
    archived
    likeCount
    commentCount
    bookmarkCount
    user {
      id
      fullName
    }
  }
}
    `;

/**
 * __useAdminFeedPostsQuery__
 *
 * To run a query within a React component, call `useAdminFeedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useAdminFeedPostsQuery(baseOptions: Apollo.QueryHookOptions<AdminFeedPostsQuery, AdminFeedPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedPostsQuery, AdminFeedPostsQueryVariables>(AdminFeedPostsDocument, options);
      }
export function useAdminFeedPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedPostsQuery, AdminFeedPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedPostsQuery, AdminFeedPostsQueryVariables>(AdminFeedPostsDocument, options);
        }
export type AdminFeedPostsQueryHookResult = ReturnType<typeof useAdminFeedPostsQuery>;
export type AdminFeedPostsLazyQueryHookResult = ReturnType<typeof useAdminFeedPostsLazyQuery>;
export type AdminFeedPostsQueryResult = Apollo.QueryResult<AdminFeedPostsQuery, AdminFeedPostsQueryVariables>;
export const CreateFeedPostDocument = gql`
    mutation CreateFeedPost($input: FeedPostInput!) {
  createFeedPost(input: $input) {
    id
  }
}
    `;
export type CreateFeedPostMutationFn = Apollo.MutationFunction<CreateFeedPostMutation, CreateFeedPostMutationVariables>;

/**
 * __useCreateFeedPostMutation__
 *
 * To run a mutation, you first call `useCreateFeedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedPostMutation, { data, loading, error }] = useCreateFeedPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedPostMutation, CreateFeedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedPostMutation, CreateFeedPostMutationVariables>(CreateFeedPostDocument, options);
      }
export type CreateFeedPostMutationHookResult = ReturnType<typeof useCreateFeedPostMutation>;
export type CreateFeedPostMutationResult = Apollo.MutationResult<CreateFeedPostMutation>;
export type CreateFeedPostMutationOptions = Apollo.BaseMutationOptions<CreateFeedPostMutation, CreateFeedPostMutationVariables>;
export const LiveStreamDocument = gql`
    query LiveStream($liveStreamId: Int!) {
  liveStream(liveStreamId: $liveStreamId) {
    id
    imageUrl
    zoomId
    zoomPassword
    startDate
    endDate
    title
    description
    archived
    createdAt
    jwtToken
  }
}
    `;

/**
 * __useLiveStreamQuery__
 *
 * To run a query within a React component, call `useLiveStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveStreamQuery({
 *   variables: {
 *      liveStreamId: // value for 'liveStreamId'
 *   },
 * });
 */
export function useLiveStreamQuery(baseOptions: Apollo.QueryHookOptions<LiveStreamQuery, LiveStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveStreamQuery, LiveStreamQueryVariables>(LiveStreamDocument, options);
      }
export function useLiveStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveStreamQuery, LiveStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveStreamQuery, LiveStreamQueryVariables>(LiveStreamDocument, options);
        }
export type LiveStreamQueryHookResult = ReturnType<typeof useLiveStreamQuery>;
export type LiveStreamLazyQueryHookResult = ReturnType<typeof useLiveStreamLazyQuery>;
export type LiveStreamQueryResult = Apollo.QueryResult<LiveStreamQuery, LiveStreamQueryVariables>;
export const AdminLiveStreamsDocument = gql`
    query AdminLiveStreams($pagination: Pagination) {
  adminLiveStreams(pagination: $pagination) {
    id
    imageUrl
    zoomId
    startDate
    endDate
    title
    description
    archived
    createdAt
  }
}
    `;

/**
 * __useAdminLiveStreamsQuery__
 *
 * To run a query within a React component, call `useAdminLiveStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLiveStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLiveStreamsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminLiveStreamsQuery(baseOptions?: Apollo.QueryHookOptions<AdminLiveStreamsQuery, AdminLiveStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminLiveStreamsQuery, AdminLiveStreamsQueryVariables>(AdminLiveStreamsDocument, options);
      }
export function useAdminLiveStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLiveStreamsQuery, AdminLiveStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminLiveStreamsQuery, AdminLiveStreamsQueryVariables>(AdminLiveStreamsDocument, options);
        }
export type AdminLiveStreamsQueryHookResult = ReturnType<typeof useAdminLiveStreamsQuery>;
export type AdminLiveStreamsLazyQueryHookResult = ReturnType<typeof useAdminLiveStreamsLazyQuery>;
export type AdminLiveStreamsQueryResult = Apollo.QueryResult<AdminLiveStreamsQuery, AdminLiveStreamsQueryVariables>;
export const CreateLiveStreamDocument = gql`
    mutation CreateLiveStream($liveStreamInput: LiveStreamInput!) {
  createLiveStream(liveStreamInput: $liveStreamInput) {
    id
  }
}
    `;
export type CreateLiveStreamMutationFn = Apollo.MutationFunction<CreateLiveStreamMutation, CreateLiveStreamMutationVariables>;

/**
 * __useCreateLiveStreamMutation__
 *
 * To run a mutation, you first call `useCreateLiveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveStreamMutation, { data, loading, error }] = useCreateLiveStreamMutation({
 *   variables: {
 *      liveStreamInput: // value for 'liveStreamInput'
 *   },
 * });
 */
export function useCreateLiveStreamMutation(baseOptions?: Apollo.MutationHookOptions<CreateLiveStreamMutation, CreateLiveStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLiveStreamMutation, CreateLiveStreamMutationVariables>(CreateLiveStreamDocument, options);
      }
export type CreateLiveStreamMutationHookResult = ReturnType<typeof useCreateLiveStreamMutation>;
export type CreateLiveStreamMutationResult = Apollo.MutationResult<CreateLiveStreamMutation>;
export type CreateLiveStreamMutationOptions = Apollo.BaseMutationOptions<CreateLiveStreamMutation, CreateLiveStreamMutationVariables>;
export const ProTeamsDocument = gql`
    query ProTeams {
  proTeams {
    id
    imageUrl
    name
    archived
  }
}
    `;

/**
 * __useProTeamsQuery__
 *
 * To run a query within a React component, call `useProTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProTeamsQuery(baseOptions?: Apollo.QueryHookOptions<ProTeamsQuery, ProTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProTeamsQuery, ProTeamsQueryVariables>(ProTeamsDocument, options);
      }
export function useProTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProTeamsQuery, ProTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProTeamsQuery, ProTeamsQueryVariables>(ProTeamsDocument, options);
        }
export type ProTeamsQueryHookResult = ReturnType<typeof useProTeamsQuery>;
export type ProTeamsLazyQueryHookResult = ReturnType<typeof useProTeamsLazyQuery>;
export type ProTeamsQueryResult = Apollo.QueryResult<ProTeamsQuery, ProTeamsQueryVariables>;
export const AddProTeamDocument = gql`
    mutation AddProTeam($input: ProTeamInput!) {
  addProTeam(input: $input) {
    id
  }
}
    `;
export type AddProTeamMutationFn = Apollo.MutationFunction<AddProTeamMutation, AddProTeamMutationVariables>;

/**
 * __useAddProTeamMutation__
 *
 * To run a mutation, you first call `useAddProTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProTeamMutation, { data, loading, error }] = useAddProTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddProTeamMutation, AddProTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProTeamMutation, AddProTeamMutationVariables>(AddProTeamDocument, options);
      }
export type AddProTeamMutationHookResult = ReturnType<typeof useAddProTeamMutation>;
export type AddProTeamMutationResult = Apollo.MutationResult<AddProTeamMutation>;
export type AddProTeamMutationOptions = Apollo.BaseMutationOptions<AddProTeamMutation, AddProTeamMutationVariables>;
export const AdminTeamsDocument = gql`
    query AdminTeams($pagination: Pagination) {
  adminTeams(pagination: $pagination) {
    id
    name
    code
    location
    createdAt
    playerCount
    archived
    coach {
      id
      fullName
    }
  }
}
    `;

/**
 * __useAdminTeamsQuery__
 *
 * To run a query within a React component, call `useAdminTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTeamsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminTeamsQuery(baseOptions?: Apollo.QueryHookOptions<AdminTeamsQuery, AdminTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminTeamsQuery, AdminTeamsQueryVariables>(AdminTeamsDocument, options);
      }
export function useAdminTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTeamsQuery, AdminTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminTeamsQuery, AdminTeamsQueryVariables>(AdminTeamsDocument, options);
        }
export type AdminTeamsQueryHookResult = ReturnType<typeof useAdminTeamsQuery>;
export type AdminTeamsLazyQueryHookResult = ReturnType<typeof useAdminTeamsLazyQuery>;
export type AdminTeamsQueryResult = Apollo.QueryResult<AdminTeamsQuery, AdminTeamsQueryVariables>;
export const TeamNameByCodeDocument = gql`
    query TeamNameByCode($code: String!) {
  teamNameByCode(code: $code) {
    name
  }
}
    `;

/**
 * __useTeamNameByCodeQuery__
 *
 * To run a query within a React component, call `useTeamNameByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamNameByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamNameByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTeamNameByCodeQuery(baseOptions: Apollo.QueryHookOptions<TeamNameByCodeQuery, TeamNameByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamNameByCodeQuery, TeamNameByCodeQueryVariables>(TeamNameByCodeDocument, options);
      }
export function useTeamNameByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamNameByCodeQuery, TeamNameByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamNameByCodeQuery, TeamNameByCodeQueryVariables>(TeamNameByCodeDocument, options);
        }
export type TeamNameByCodeQueryHookResult = ReturnType<typeof useTeamNameByCodeQuery>;
export type TeamNameByCodeLazyQueryHookResult = ReturnType<typeof useTeamNameByCodeLazyQuery>;
export type TeamNameByCodeQueryResult = Apollo.QueryResult<TeamNameByCodeQuery, TeamNameByCodeQueryVariables>;
export const AddEventConfirmationDocument = gql`
    mutation AddEventConfirmation($input: EventConfirmationInput!) {
  addEventConfirmation(input: $input) {
    id
    userId
    eventId
    createdAt
    updatedAt
    going
  }
}
    `;
export type AddEventConfirmationMutationFn = Apollo.MutationFunction<AddEventConfirmationMutation, AddEventConfirmationMutationVariables>;

/**
 * __useAddEventConfirmationMutation__
 *
 * To run a mutation, you first call `useAddEventConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventConfirmationMutation, { data, loading, error }] = useAddEventConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<AddEventConfirmationMutation, AddEventConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventConfirmationMutation, AddEventConfirmationMutationVariables>(AddEventConfirmationDocument, options);
      }
export type AddEventConfirmationMutationHookResult = ReturnType<typeof useAddEventConfirmationMutation>;
export type AddEventConfirmationMutationResult = Apollo.MutationResult<AddEventConfirmationMutation>;
export type AddEventConfirmationMutationOptions = Apollo.BaseMutationOptions<AddEventConfirmationMutation, AddEventConfirmationMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($phone: String!, $password: String!) {
  login(phoneNumber: $phone, password: $password) {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($userInput: UserInput!) {
  register(userInput: $userInput) {
    token
    user {
      ...CurrentUserFields
    }
  }
}
    ${CurrentUserFieldsFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const AdminModifyUserDocument = gql`
    mutation AdminModifyUser($userId: String, $modifyUserInput: AdminModifyUserInput!) {
  adminModifyUser(modifyUserInput: $modifyUserInput, userId: $userId) {
    ...GenericUserFields
  }
}
    ${GenericUserFieldsFragmentDoc}`;
export type AdminModifyUserMutationFn = Apollo.MutationFunction<AdminModifyUserMutation, AdminModifyUserMutationVariables>;

/**
 * __useAdminModifyUserMutation__
 *
 * To run a mutation, you first call `useAdminModifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminModifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminModifyUserMutation, { data, loading, error }] = useAdminModifyUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      modifyUserInput: // value for 'modifyUserInput'
 *   },
 * });
 */
export function useAdminModifyUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminModifyUserMutation, AdminModifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminModifyUserMutation, AdminModifyUserMutationVariables>(AdminModifyUserDocument, options);
      }
export type AdminModifyUserMutationHookResult = ReturnType<typeof useAdminModifyUserMutation>;
export type AdminModifyUserMutationResult = Apollo.MutationResult<AdminModifyUserMutation>;
export type AdminModifyUserMutationOptions = Apollo.BaseMutationOptions<AdminModifyUserMutation, AdminModifyUserMutationVariables>;
export const DeleteFeedPostDocument = gql`
    mutation DeleteFeedPost($id: ID!) {
  deleteFeedPost(id: $id) {
    success
    message
  }
}
    `;
export type DeleteFeedPostMutationFn = Apollo.MutationFunction<DeleteFeedPostMutation, DeleteFeedPostMutationVariables>;

/**
 * __useDeleteFeedPostMutation__
 *
 * To run a mutation, you first call `useDeleteFeedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedPostMutation, { data, loading, error }] = useDeleteFeedPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedPostMutation, DeleteFeedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedPostMutation, DeleteFeedPostMutationVariables>(DeleteFeedPostDocument, options);
      }
export type DeleteFeedPostMutationHookResult = ReturnType<typeof useDeleteFeedPostMutation>;
export type DeleteFeedPostMutationResult = Apollo.MutationResult<DeleteFeedPostMutation>;
export type DeleteFeedPostMutationOptions = Apollo.BaseMutationOptions<DeleteFeedPostMutation, DeleteFeedPostMutationVariables>;
export const UpdateFeedPostDocument = gql`
    mutation UpdateFeedPost($id: ID!, $input: UpdateFeedPostInput!) {
  updateFeedPost(id: $id, input: $input) {
    id
    imageUrl
    category
    contentSource
    description
    createdAt
    archived
    likeCount
    commentCount
    bookmarkCount
  }
}
    `;
export type UpdateFeedPostMutationFn = Apollo.MutationFunction<UpdateFeedPostMutation, UpdateFeedPostMutationVariables>;

/**
 * __useUpdateFeedPostMutation__
 *
 * To run a mutation, you first call `useUpdateFeedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedPostMutation, { data, loading, error }] = useUpdateFeedPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeedPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedPostMutation, UpdateFeedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedPostMutation, UpdateFeedPostMutationVariables>(UpdateFeedPostDocument, options);
      }
export type UpdateFeedPostMutationHookResult = ReturnType<typeof useUpdateFeedPostMutation>;
export type UpdateFeedPostMutationResult = Apollo.MutationResult<UpdateFeedPostMutation>;
export type UpdateFeedPostMutationOptions = Apollo.BaseMutationOptions<UpdateFeedPostMutation, UpdateFeedPostMutationVariables>;
export const SendPushToAllDocument = gql`
    mutation SendPushToAll($text: String, $title: String, $owners: Boolean, $ids: String) {
  sendPushToAll(text: $text, title: $title, owners: $owners, ids: $ids)
}
    `;
export type SendPushToAllMutationFn = Apollo.MutationFunction<SendPushToAllMutation, SendPushToAllMutationVariables>;

/**
 * __useSendPushToAllMutation__
 *
 * To run a mutation, you first call `useSendPushToAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushToAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushToAllMutation, { data, loading, error }] = useSendPushToAllMutation({
 *   variables: {
 *      text: // value for 'text'
 *      title: // value for 'title'
 *      owners: // value for 'owners'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSendPushToAllMutation(baseOptions?: Apollo.MutationHookOptions<SendPushToAllMutation, SendPushToAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPushToAllMutation, SendPushToAllMutationVariables>(SendPushToAllDocument, options);
      }
export type SendPushToAllMutationHookResult = ReturnType<typeof useSendPushToAllMutation>;
export type SendPushToAllMutationResult = Apollo.MutationResult<SendPushToAllMutation>;
export type SendPushToAllMutationOptions = Apollo.BaseMutationOptions<SendPushToAllMutation, SendPushToAllMutationVariables>;
export const SendPushAboutFeedDocument = gql`
    mutation SendPushAboutFeed($text: String, $id: Int) {
  sendPushAboutFeed(text: $text, id: $id)
}
    `;
export type SendPushAboutFeedMutationFn = Apollo.MutationFunction<SendPushAboutFeedMutation, SendPushAboutFeedMutationVariables>;

/**
 * __useSendPushAboutFeedMutation__
 *
 * To run a mutation, you first call `useSendPushAboutFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushAboutFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushAboutFeedMutation, { data, loading, error }] = useSendPushAboutFeedMutation({
 *   variables: {
 *      text: // value for 'text'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPushAboutFeedMutation(baseOptions?: Apollo.MutationHookOptions<SendPushAboutFeedMutation, SendPushAboutFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPushAboutFeedMutation, SendPushAboutFeedMutationVariables>(SendPushAboutFeedDocument, options);
      }
export type SendPushAboutFeedMutationHookResult = ReturnType<typeof useSendPushAboutFeedMutation>;
export type SendPushAboutFeedMutationResult = Apollo.MutationResult<SendPushAboutFeedMutation>;
export type SendPushAboutFeedMutationOptions = Apollo.BaseMutationOptions<SendPushAboutFeedMutation, SendPushAboutFeedMutationVariables>;
export const FeedPostDocument = gql`
    query FeedPost($feedPostId: Int!) {
  feedPost(feedPostId: $feedPostId) {
    id
    imageUrl
    thumbnailUrl
    description
    category
  }
}
    `;

/**
 * __useFeedPostQuery__
 *
 * To run a query within a React component, call `useFeedPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedPostQuery({
 *   variables: {
 *      feedPostId: // value for 'feedPostId'
 *   },
 * });
 */
export function useFeedPostQuery(baseOptions: Apollo.QueryHookOptions<FeedPostQuery, FeedPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedPostQuery, FeedPostQueryVariables>(FeedPostDocument, options);
      }
export function useFeedPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedPostQuery, FeedPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedPostQuery, FeedPostQueryVariables>(FeedPostDocument, options);
        }
export type FeedPostQueryHookResult = ReturnType<typeof useFeedPostQuery>;
export type FeedPostLazyQueryHookResult = ReturnType<typeof useFeedPostLazyQuery>;
export type FeedPostQueryResult = Apollo.QueryResult<FeedPostQuery, FeedPostQueryVariables>;